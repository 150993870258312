<template>
  <div class="container">
    <BaseH1 :text="'Kursy'" />

    <div class="wrapper-checkboxes">
      <InputCheckboxGroupSticked
        v-model="application.educationCourses"
        :name="'education-courses'"
        :options="educationCoursesOptions"
        :columns="1"
      />
      <TextHint
        :text="'Naciśnij dalej, jeśli żadna z opcji nie jest odpowiednia'"
      />
    </div>

    <transition>
      <InputTextTextareaComment v-model="application.educationComment" />
    </transition>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextHint from "@/components/UI/TextHint.vue";
import InputTextTextareaComment from "@/components/UI/InputTextTextareaComment.vue";
import InputCheckboxGroupSticked from "@/components/UI/Base/InputCheckboxGroupSticked.vue";

export default {
  components: {
    BaseH1,
    TextHint,
    InputTextTextareaComment,
    InputCheckboxGroupSticked,
  },

  props: {
    educationCourses: Array,
    educationComment: String,
  },

  emits: ["update:educationCourses", "update:educationComment"],

  setup(props, { emit }) {
    const application = reactive({
      educationCourses: props.educationCourses,
      educationComment: props.educationComment,
    });

    watch(
      () => application.educationCourses,
      (newCourses) => emit("update:educationCourses", newCourses)
    );
    watch(
      () => application.educationComment,
      (newComment) => emit("update:educationComment", newComment)
    );
    watch(Object.values(toRefs(props)), () => {
      application.educationCourses = props.educationCourses;
      application.educationComment = props.educationComment;
    });

    const { DICTIONARY } = useApplicationNannyDictionary();
    const educationCoursesOptions = Object.values(DICTIONARY.education.courses);

    return {
      application,
      educationCoursesOptions,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.wrapper-checkboxes {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 40px;
}

@media (max-width: 1200px) {
}
</style>
