<template>
  <div class="content">
    <ApplicationNannyInputEducationCourses
      v-model:education-courses="applicationNanny.education.courses"
      v-model:education-comment="applicationNanny.education.comment"
    />

    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreateResponsibilities' }"
    />
  </div>
</template>

<script>
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import ApplicationNannyInputEducationCourses from "@/components/Application/Nanny/ApplicationNannyInputEducationCourses.vue";

export default {
  components: {
    ButtonPrimaryNext,
    ApplicationNannyInputEducationCourses,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    return {
      applicationNanny,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
</style>
